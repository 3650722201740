<template>
  <div class="batch-search">
            <div class="top-box">
              <div class="top-title">批次查询</div>
              <div class="btn-box">
                <div class="btn" @click="handleSearchClick"><i class="el-icon-search"></i> 查询</div>
              </div>
            </div>
            <el-row class="form-group">
              <el-col :span="6" class="form-item">
                  <div class="label">省份</div>
                  <div class="input-box">
                    <el-select class="form-control" v-model="province" 
                    v-loading="provinceListLoading"
                    element-loading-spinner="el-icon-loading">
                      <el-option key="0" label="请选择..." value=""></el-option>
                      <el-option
                        v-for="item in provinceList"
                        :key="item.key"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
              </el-col>
            </el-row>

            <!--  -->
            <div class="table-container-body">
              <el-table :data="tableData" height="500" stripe class="table-body"
              :header-cell-style="{background:'#e6e6e6', color: '#646464'}"
              v-loading="tableLoading">
                <el-table-column prop="year" label="年份">
                </el-table-column>
                <el-table-column prop="province" label="省份">
                </el-table-column>
                <el-table-column prop="subject" label="科类">
                </el-table-column>
                <el-table-column prop="batch" label="批次">
                </el-table-column>
                <el-table-column prop="score" label="分数">
                </el-table-column>
                <el-table-column prop="type" label="高报类型">
                </el-table-column>
              </el-table>
            </div>
            <div class="table-container-footer">
              <el-pagination
                layout="total, prev, pager, next, sizes, jumper"
                :page-sizes="[10, 50, 100, 500]"
                :page-size="pageSize"
                :total="tableTotal"
                :current-page="pageNo"
                @current-change="pageChange"
                @size-change="sizeChange"
              >
              </el-pagination>
            </div>
            <!--  -->
          </div>
</template>
<script>
export default {
  name: "batch",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      pageNo: 1,
      pageSize:10,
      tableTotal:0,
      tableData: [],
      tableLoading:false,
      provinceListLoading:false,
      province:"",
      provinceList:[]
    };
  },
  methods: {
    initSelectData(){
      this.provinceListLoading = true;
      //获取省份
      this.$http.post(this.$apis.getProvinceOrCityList+"?type=province").then((res) => {
        this.provinceListLoading = false;
        if (res.data) {
          this.$store.commit("setProvinceList", res.data);
          this.provinceList = res.data.map(r=>{
            return {
              key:r.id,
              label:r.name,
              value:r.id
            }
          });
        }
      });
    },
    initTableData(){
      this.tableLoading=true;
      var data={
        batch: "",
        courseType: "",
        currentPage: this.pageNo,
        enrollType: "",
        pageSize: this.pageSize,
        province: this.province,
        year: null
      }
      //获取分页表格数据
      this.$http.post(this.$apis.getBatchByPageCondition,data).then((res) => {
        this.tableLoading=false;
        if (res.data) {
          this.tableData = res.data.list.map(r=>{
            return {
              id:r.id,
              year: r.year,
              province: r.provinceName,
              subject: r.courseType,
              batch: r.batch,
              score: r.score,
              type: r.enrollType,
            }
          });
          this.tableTotal = res.data.total;
        }
      });
    },
    handleSearchClick(){
      this.pageNo = 1;
      this.initTableData();
    },
    pageChange(val) {
      this.pageNo = val;
      this.initTableData();
    },
    sizeChange(val){
      this.pageSize = val;
      this.initTableData();
    }
  },
  mounted(){
    this.initSelectData();
    this.initTableData();
  },
};
</script>
<style lang="less" scoped>
.batch-search {
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  border-radius: 5px;
  border: 1px solid rgba(230, 230, 230, 100);

  .top-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px;
    border-bottom: 1px solid rgba(230, 230, 230, 100);

    .top-title {
      color: rgba(16, 16, 16, 100);
      font-size: 20px;
      font-weight: 700;
      padding-left: 20px;
      position: relative;
    }
    .top-title::after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      height: 27px;
      width: 3px;
      background-color: #ff450b;
    }

    .btn-box {
      display: flex;
      align-items: center;
      .btn {
        line-height: 32px;
        width: 95px;
        border-radius: 4px;
        background-color: rgba(255, 69, 11, 100);
        text-align: center;
        color: #fff;
        margin-left: 15px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
  .form-group{
      padding: 0px 20px;
    .form-item {
      display: flex;
      align-items: center;
      padding: 15px 0;
      .label {
        display: flex;
        justify-content: flex-end;
        color: rgba(100, 100, 100, 100);
        font-size: 14px;
        width: 70px;
        padding-right:10px;
      }
      .input-box {
          display: flex;
          width: calc(100% - 100px);
      }
      .required-label{
            color: rgb(255, 0, 0);
      }
      .action-link{
          font-size: 16px;
          cursor:pointer;
          color: #ff450b;
          padding-right: 20px;
      }
    }
  }
  .form-control{
      width: 100%;
  }
  .table-container-body {
    padding: 0 20px;
    .table-body{
      width: 100%;
      overflow: auto;
      border: 1px solid #e6e6e6;
    }
    .table-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-around;
      .table-btn {
        cursor: pointer;
        width: 60px;
        line-height: 28px;
        border-radius: 14px;
        color: rgba(121, 160, 241, 100);
        font-size: 12px;
        text-align: center;
        border: 1px solid rgba(121, 160, 241, 100);
      }
    }
  }
  .table-container-footer{
    padding: 20px;
  }
}
</style>